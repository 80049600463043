import React, { useState } from "react";
import styles from "./symptom.module.css";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

function Symptom(props) {
  const [fever, setFever] = useState(false);
  const [cough, setCough] = useState(false);
  const [noBreathe, setNoBreathe] = useState(false);
  const [chill, setChill] = useState(false);
  const [musclePain, setMusclePain] = useState(false);
  const [headache, setHeadache] = useState(false);
  const [soreThroat, setSoreThroat] = useState(false);
  const [lossSmellTaste, setLossSmellTaste] = useState(false);
  const [tired, setTired] = useState(false);
  const [wantEat, setWantEat] = useState(false);
  const [garae, setGarae] = useState(false);
  const [osim, setOsim] = useState(false);
  const [guto, setGuto] = useState(false);
  const [sulsa, setSulsa] = useState(false);
  const [whirl, setWhirl] = useState(false);
  const [snot, setSnot] = useState(false);
  const [etc, setEtc] = useState(false);
  const [etcIndex, setEtcIndex] = useState("");

  const symptomObject = {
    발열: fever,
    기침: cough,
    호흡곤란: noBreathe,
    오한: chill,
    근육통: musclePain,
    두통: headache,
    인후통: soreThroat,
    "후각·미각손실": lossSmellTaste,
    피로: tired,
    식욕감소: wantEat,
    가래: garae,
    오심: osim,
    구토: guto,
    설사: sulsa,
    어지러움: whirl,
    "콧물·코막힘": snot,
    기타증상: etcIndex,
  };
  const sendDB = [];

  return (
    <div className={styles.container}>
      {!etc ? (
        <>
          <div
            className={styles.box}
            style={{ backgroundColor: fever ? "#77c66e" : "white" }}
            onClick={() => {
              setFever(!fever);
            }}
          >
            <img
              src="/symptoms_icon/fever.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>발열</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: cough ? "#77c66e" : "white" }}
            onClick={() => {
              setCough(!cough);
            }}
          >
            <img
              src="/symptoms_icon/cough.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>기침</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: noBreathe ? "#77c66e" : "white" }}
            onClick={() => {
              setNoBreathe(!noBreathe);
            }}
          >
            <img
              src="/symptoms_icon/noBreathe.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>호흡곤란</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: chill ? "#77c66e" : "white" }}
            onClick={() => {
              setChill(!chill);
            }}
          >
            <img
              src="/symptoms_icon/chill.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>오한</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: musclePain ? "#77c66e" : "white" }}
            onClick={() => {
              setMusclePain(!musclePain);
            }}
          >
            <img
              src="/symptoms_icon/musclePain.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>근육통</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: headache ? "#77c66e" : "white" }}
            onClick={() => {
              setHeadache(!headache);
            }}
          >
            <img
              src="/symptoms_icon/headache.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>두통</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: soreThroat ? "#77c66e" : "white" }}
            onClick={() => {
              setSoreThroat(!soreThroat);
            }}
          >
            <img
              src="/symptoms_icon/soreThroat.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>인후통</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: lossSmellTaste ? "#77c66e" : "white" }}
            onClick={() => {
              setLossSmellTaste(!lossSmellTaste);
            }}
          >
            <img
              src="/symptoms_icon/lossSmellTaste.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>후각·미각손실</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: tired ? "#77c66e" : "white" }}
            onClick={() => {
              setTired(!tired);
            }}
          >
            <img
              src="/symptoms_icon/tired.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>피로</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: wantEat ? "#77c66e" : "white" }}
            onClick={() => {
              setWantEat(!wantEat);
            }}
          >
            <img
              src="/symptoms_icon/wantEat.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>식욕감소</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: garae ? "#77c66e" : "white" }}
            onClick={() => {
              setGarae(!garae);
            }}
          >
            <img
              src="/symptoms_icon/garae.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>가래</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: osim ? "#77c66e" : "white" }}
            onClick={() => {
              setOsim(!osim);
            }}
          >
            <img
              src="/symptoms_icon/osim.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>오심</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: guto ? "#77c66e" : "white" }}
            onClick={() => {
              setGuto(!guto);
            }}
          >
            <img
              src="/symptoms_icon/guto.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>구토</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: sulsa ? "#77c66e" : "white" }}
            onClick={() => {
              setSulsa(!sulsa);
            }}
          >
            <img
              src="/symptoms_icon/sulsa.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>설사</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: whirl ? "#77c66e" : "white" }}
            onClick={() => {
              setWhirl(!whirl);
            }}
          >
            <img
              src="/symptoms_icon/whirl.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>어지러움</div>
          </div>
          <div
            className={styles.box}
            style={{ backgroundColor: snot ? "#77c66e" : "white" }}
            onClick={() => {
              setSnot(!snot);
            }}
          >
            <img
              src="/symptoms_icon/snot.png"
              className={styles.icon}
              alt="symptom_item"
            />
            <div>콧물·코막힘</div>
          </div>
        </>
      ) : (
        <Form.Group className="mb-3">
          <Form.Label>기타 증상</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            placeholder="기타 증상"
            onChange={(e) => {
              setEtcIndex(e.target.value);
            }}
            value={etcIndex}
          />
        </Form.Group>
      )}
      <div
        className={styles.box}
        style={{ backgroundColor: etc ? "#77c66e" : "white" }}
        onClick={() => {
          setEtc(!etc);
        }}
      >
        <div>기타</div>
      </div>
      <span
        className={styles.button}
        onClick={() => {
          if (
            fever === false &&
            cough === false &&
            noBreathe === false &&
            chill === false &&
            musclePain === false &&
            headache === false &&
            soreThroat === false &&
            lossSmellTaste === false &&
            tired === false &&
            wantEat === false &&
            garae === false &&
            osim === false &&
            guto === false &&
            sulsa === false &&
            whirl === false &&
            snot === false &&
            etc === false
          ) {
            toast.error("증상을 선택해주세요.", {
              autoClose: 4000,
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            props.handleSymptom(sendDB);
            Object.entries(symptomObject).map((t) => {
              if (t[1] === true) {
                sendDB.push(t[0]);
                props.handleComplete();
              }
            });
            if (etc === true) {
              if (etcIndex !== "") {
                sendDB.push(etcIndex);
                props.handleComplete();
              } else {
                toast.error("기타 증상을 입력해주세요.", {
                  autoClose: 4000,
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          }
        }}
      >
        <span className={styles.button_text}>증상 선택완료</span>
      </span>
    </div>
  );
}

export default Symptom;
