import React from "react";

function text(props) {
  return (
    <div>
      <p className="ls2 lh6 bs5 ts4">
        <em className="emphasis">
          &lt; 김숙자소아청소년병원 &gt;('covid.ksz.kr'이하 '코로나 19 정보 관리
          시스템')
        </em>
        은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고
        이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과
        같이 개인정보 처리방침을 수립&middot;공개합니다.
      </p>
      <p className="ls2">&nbsp;</p>
      <p className="sub_p mgt30">
        <strong>
          제1조(개인정보의 처리목적)
          <br />
          <br />
          &lt; 김숙자소아청소년병원 &gt;(이)가 개인정보 보호법 제32조에 따라
          등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.
        </strong>
      </p>
      <ul className="list_indent2 mgt10">
        <li className="tt">
          <strong>
            1. 개인정보 파일명 : 김숙자소아청소년병원-코로나 19 정보 관리 시스템
            개인정보처리방침
          </strong>
        </li>
        <li>
          개인정보의 처리목적 : 본 병원의 호흡기 클리닉에 방문하는 이용자가 더욱
          신속하게 코로나 19 검사를 진행하기 위하여 환자의 개인정보를 수집 및
          이용함.
        </li>
        <li>수집방법 : 홈페이지</li>
        <li>
          보유근거 : 본 병원에서 진단 및 치료를 위해 보유하며, 의료법 및
          개인정보보호법 기준에 근거함
        </li>
        <li>보유기간 : 1년</li>
      </ul>
      <p>
        <br />
        <br />
      </p>
      <p className="lh6 bs4">
        <strong>제2조(개인정보처리 위탁)</strong>
        <br />
        <br />
        ①&nbsp;<em className="emphasis">&lt; 김숙자소아청소년병원 &gt;</em>
        은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보를 국외에
        위탁하고 있습니다.
      </p>
      <ul className="list_indent2 mgt10">
        <li className="tt">
          1. &lt; Google Firebase 데이터베이스 및 호스팅 &gt;
        </li>
        <li>수탁자 : Google Firebase</li>
        <li>
          위탁하는 업무의 내용 : 데이터베이스, 호스팅 서버 구축, 관리 및
          개인정보 저장
        </li>
        <li>보유기간 : 1년</li>
        <li>이전 항목 : 수집된 개인정보</li>
        <li>이전 국가 : 미국(us-central) 리전</li>
        <li>
          이전 일시 및 방법 : 서비스 이용시점에 네트워크 통신을 이용하여 전송
        </li>
      </ul>
      <p className="sub_p mgt10">
        ②&nbsp;
        <span className="colorLightBlue">&lt; 김숙자소아청소년병원 &gt;</span>
        은(는) 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무
        수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한,
        수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
        명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </p>
      <p className="sub_p mgt10">
        ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
        처리방침을 통하여 공개하도록 하겠습니다.
      </p>
      <p>
        <br />
        <br />
      </p>
      <p className="lh6 bs4">
        <strong>
          제3조(정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법)
        </strong>
      </p>
      <p className="ls2">
        <br />
        <br />① 정보주체는 김숙자소아청소년병원에 대해 언제든지 개인정보
        열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할 수
        있습니다.
      </p>
      <p className="sub_p">
        ② 제1항에 따른 권리 행사는김숙자소아청소년병원에 대해 「개인정보
        보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
        통하여 하실 수 있으며 김숙자소아청소년병원은(는) 이에 대해 지체 없이
        조치하겠습니다.
      </p>
      <p className="sub_p">
        ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
        대리인을 통하여 하실 수 있습니다.이 경우 &ldquo;개인정보 처리 방법에
        관한 고시(제2020-7호)&rdquo; 별지 제11호 서식에 따른 위임장을 제출하셔야
        합니다.
      </p>
      <p className="sub_p">
        ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
        제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
      </p>
      <p className="sub_p">
        ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
      </p>
      <p className="sub_p">
        ⑥ 김숙자소아청소년병원은(는) 정보주체 권리에 따른 열람의 요구,
        정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
        본인이거나 정당한 대리인인지를 확인합니다.
      </p>
      <p>
        <br />
        <br />
      </p>
      <p className="lh6 bs4">
        <strong>제4조(처리하는 개인정보의 항목 작성)</strong>
        <br />
        <br />
        ①&nbsp;<em className="emphasis">&lt; 김숙자소아청소년병원 &gt;</em>
        은(는) 다음의 개인정보 항목을 처리하고 있습니다.
      </p>
      <ul className="list_indent2 mgt10">
        <li className="tt">
          1&lt; 김숙자소아청소년병원-코로나 19 정보 관리 시스템 개인정보처리방침
          &gt;
        </li>
        <li>
          필수항목 : 휴대전화번호, 자택주소, 성별, 생년월일, 이름, 직업, 접속 IP
          정보, 법정대리인 휴대전화번호 등 을 수집함.
        </li>
      </ul>
      <p>
        <br />
        <br />
      </p>
      <p className="lh6 bs4">
        <strong>제5조(개인정보의 파기)</strong>
      </p>
      <p className="ls2">
        <em className="emphasis">
          <br />① &lt; 김숙자소아청소년병원 &gt; 은(는) 개인정보 보유기간의
          경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이
          해당 개인정보를 파기합니다.
          <br />
          <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
          처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
          보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
          옮기거나 보관장소를 달리하여 보존합니다.
          <br />
          1. 법령 근거 : 개인정보보호법 및 의료법
          <br />
          2. 보존하는 개인정보 항목 : 휴대전화번호, 자택주소, 성별, 생년월일,
          이름, 직업, 접속 IP 정보, 법정대리인 휴대전화번호 등
          <br />
          <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <br />
          1. 파기절차
          <br />
          &lt; 김숙자소아청소년병원 &gt; 은(는) 파기 사유가 발생한 개인정보를
          선정하고, &lt; 김숙자소아청소년병원 &gt; 의 개인정보 보호책임자의
          승인을 받아 개인정보를 파기합니다.
          <br />
        </em>
      </p>
      <p className="sub_p mgt10">
        <em className="emphasis">2. 파기방법</em>
      </p>
      <p className="sub_p">
        <em className="emphasis">
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
          사용합니다
        </em>
      </p>
      <p>
        <em className="emphasis">
          <br />
          <br />
        </em>
      </p>
      <p className="lh6 bs4">
        <strong>
          제6조(개인정보의 안전성 확보 조치)
          <em className="emphasis">
            <br />
            <br />
            &lt; 김숙자소아청소년병원 &gt;
          </em>
          은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
        </strong>
      </p>
      <p className="sub_p mgt10">
        1. 정기적인 자체 감사 실시
        <br />
        개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를
        실시하고 있습니다.
        <br />
        <br />
        2. 개인정보 취급 직원의 최소화 및 교육
        <br />
        개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
        개인정보를 관리하는 대책을 시행하고 있습니다.
        <br />
        <br />
        3. 내부관리계획의 수립 및 시행
        <br />
        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
        있습니다.
        <br />
        <br />
        4. 해킹 등에 대비한 기술적 대책
        <br />
        &lt;<em className="emphasis">김숙자소아청소년병원</em>&gt;('
        <em className="emphasis">코로나 19 정보 관리 시스템</em>')은 해킹이나
        컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
        보안프로그램을 설치하고 주기적인 갱신&middot;점검을 하며 외부로부터
        접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및
        차단하고 있습니다.
        <br />
        <br />
        5. 접속기록의 보관 및 위변조 방지
        <br />
        개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고
        있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
        고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고
        있습니다.
        <br />
        또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고
        있습니다.
        <br />
        <br />
        6. 개인정보에 대한 접근 제한
        <br />
        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
        부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를
        하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
        있습니다.
        <br />
        <br />
        7. 문서보안을 위한 잠금장치 사용
        <br />
        개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에
        보관하고 있습니다.
        <br />
        <br />
        8. 비인가자에 대한 출입 통제
        <br />
        개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해
        출입통제 절차를 수립, 운영하고 있습니다.
        <br />
        <br />
      </p>
      <p>
        <em className="emphasis">
          <br />
          <br />
        </em>
      </p>
      <p className="lh6 bs4">
        <strong>
          제7조(개인정보 자동 수집 장치의 설치&bull;운영 및 거부에 관한 사항)
        </strong>
      </p>
      <p className="ls2">
        <br />
        <br />
        김숙자소아청소년병원 은(는) 정보주체의 이용정보를 저장하고 수시로
        불러오는 &lsquo;쿠키(cookie)&rsquo;를 사용하지 않습니다.
      </p>
      <p className="sub_p mgt30">
        <strong>제8조 (개인정보 보호책임자)</strong>
      </p>
      <p className="sub_p mgt10">
        ①&nbsp;<span className="colorLightBlue">김숙자소아청소년병원</span>
        &nbsp;은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
        처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보 보호책임자를 지정하고 있습니다.
      </p>
      <ul className="list_indent2 mgt10">
        <li className="tt">▶ 개인정보 보호책임자</li>
        <li>성명 :김숙자</li>
        <li>직책 :대표</li>
        <li>직급 :대표</li>
        <li>연락처 :043-263-8280, kimgene@unitel.co.kr, 043-215-8288(FAX)</li>
      </ul>
      <p className="sub_p">※ 개인정보 보호 담당부서로 연결됩니다.</p>
      <p>&nbsp;</p>
      <ul className="list_indent2 mgt10">
        <li className="tt">▶ 개인정보 보호 담당부서</li>
        <li>부서명 : 원무과</li>
      </ul>
      <p className="sub_p">
        ② 정보주체께서는 김숙자소아청소년병원 의 서비스(또는 사업)을
        이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
        등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
        김숙자소아청소년병원 은(는) 정보주체의 문의에 대해 지체 없이 답변 및
        처리해드릴 것입니다.
      </p>
      <p className="sub_p mgt30">
        <strong>
          제9조(개인정보 열람청구)
          <br />
          정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
          아래의 부서에 할 수 있습니다.
          <br />
          &lt; 김숙자소아청소년병원 &gt;은(는) 정보주체의 개인정보 열람청구가
          신속하게 처리되도록 노력하겠습니다.
        </strong>
      </p>
      <ul className="list_indent2 mgt10">
        <li className="tt">▶ 개인정보 열람청구 접수&middot;처리 부서</li>
        <li>부서명 : 원무과</li>
      </ul>
      <p>
        <em className="emphasis">
          <br />
          <br />
        </em>
      </p>
      <p className="lh6 bs4">
        <strong>제10조(권익침해 구제방법)</strong>
      </p>
      <p>
        <em className="emphasis">
          <br />
          <br />
          정보주체는 개인정보침해로 인한 구제를 받기 위하여
          개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
          분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
          신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
          <br />
          <br />
          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
          <br />
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
          <br />
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
          <br />
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
          <br />
          <br />
          「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
          정정&middot;삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에
          대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
          이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
          청구할 수 있습니다.
          <br />
          <br />※ 행정심판에 대해 자세한 사항은
          중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
          <br />
          <br />
        </em>
      </p>
      <p className="lh6 bs4">
        <strong>제11조(개인정보 처리방침 변경)</strong>
      </p>
      <p>
        <em className="emphasis">&nbsp;</em>
      </p>
      <p>&nbsp;</p>
      <p className="sub_p">
        ① 이 개인정보처리방침은 2022년 1월 12부터 적용됩니다.
      </p>
      <p>&nbsp;</p>
    </div>
  );
}

export default text;
