import React from "react";
import Modal from "react-modal/lib/components/Modal";
import IsolationNotice from "./isolationNotice";
import styles from "./isolation.module.css";
import { IoClose } from "react-icons/io5";

function Isolation(props) {
  return (
    <div>
      <Modal
        style={{
          content: {
            top: "10%",
            left: "5%",
            right: "5%",
            bottom: "10%",
            border: "1px solid #ccc",
            background: "white",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "10px",
            outline: "none",
            padding: "15px",
          },
        }}
        ariaHideApp={false}
        isOpen={props.modalState}
        onRequestClose={() => {
          props.handleModalClose();
        }}
      >
        <div>
          <span
            className={styles.close_button}
            onClick={props.handleModalClose}
          >
            <IoClose size="25px" color="white" />
            <strong className={styles.close_button_text}>확인</strong>
          </span>
          <div
            style={{
              height: "65vh",
              overflow: "scroll",
            }}
          >
            <IsolationNotice />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Isolation;
