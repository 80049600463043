import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import HeaderForm from "../../../header_form/header_form.jsx";
import styles from "./form1.module.css";
import { ToastContainer, toast } from "react-toastify";
import DaumAdressAPI from "../../../daumAdressAPI.jsx";
import Form2 from "../form2/form2.jsx";
import UserPrivacyModal from "../../../privacy/userPrivacyModal.jsx";
import Isolation from "../../../isolation/isolation.jsx";
import { Helmet } from "react-helmet";
function Form1(props) {
  const [pageInfo, setPageInfo] = useState("1");
  // ----- Props로 전달할 States
  const [nameValue, setNameValue] = useState("");
  const [isGoForeign, setIsGoForeign] = useState(false);
  const [idValue, setIdValue] = useState("");
  //생년원일일 경우
  const [ifBirthDate, setIfBirthDate] = useState(false);
  const [idValidated, serIdValidated] = useState(false);

  const [phoneValue, setPhoneValue] = useState("");
  // 미성년자 용 ---
  const [isChild, setIsChild] = useState(false);
  const [parentPhone, setParentPhone] = useState("");
  // 해외출국용 변수 ---
  const [engNameValue, setEngNameValue] = useState("");
  const [passportNumValue, setPassportNumValue] = useState("");

  const [gender, setGender] = useState("");
  const [job, setJob] = useState("");
  const [detailJob, setDetailJob] = useState("");
  // 주소 관련 ---
  const [postcode, setPostcode] = useState("");
  const [fullAdress, setFullAdress] = useState("");
  const [detailAdress, setDetailAdress] = useState("");
  const [engAddress, setEngAddress] = useState("");
  // 개인정보 관련 ---
  const [agreeState, setAgreeState] = useState(false);

  // Props로 전달할 States -----

  // --- Modal States
  const [modalAdressState, setModalAdressState] = useState(false);
  const [modalPrivacyState, setModalPrivacyState] = useState(false);
  const [modalIsolationState, setModalIsolationState] = useState(false);
  // 개인정보 약관 봤는지, 안봤는지
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [showModalIsolation, setShowModalIsolation] = useState(false);

  const sendProps = {
    nameValue: nameValue,
    isGoForeign: isGoForeign,
    idValue: idValue,
    phoneValue: phoneValue,
    isChild: isChild,
    parentPhone: parentPhone,
    engNameValue: engNameValue,
    passportNumValue: passportNumValue,
    gender: gender,
    job: job,
    detailJob: detailJob,
    postcode: postcode,
    fullAdress: fullAdress,
    detailAdress: detailAdress,
    engAddress: engAddress,
  };

  function handleNameChange(e) {
    const nameRegex =
      /^[a-z|A-Z|ㄱ-ㅎㅏ-ㅣ가-힣ᆢ|\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55|\u1100-\u1112]+$/;
    if (e.target.value === "" || nameRegex.test(e.target.value)) {
      setNameValue(e.target.value);
    } else {
      toast.error("이름에 특수문자는 입력하실 수 없습니다.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  function handleIdChange(e) {
    if (!ifBirthDate) {
      const idRegex = /^[0-9\b -]{0,14}$/;
      if (idRegex.test(e.target.value)) {
        setIdValue(e.target.value);
      }
    } else {
      const idRegex = /^[0-9\b -]{0,6}$/;
      if (idRegex.test(e.target.value)) {
        setIdValue(e.target.value);
      }
    }
  }
  function handlePhoneChange(e) {
    const phoneRegex = /^[0-9\b-]{0,13}$/;
    if (phoneRegex.test(e.target.value)) {
      setPhoneValue(e.target.value);
    }
  }
  function handleParentPhoneChange(e) {
    const phoneRegex = /^[0-9\b -]{0,13}$/;
    if (phoneRegex.test(e.target.value)) {
      setParentPhone(e.target.value);
    }
  }
  //
  function handleEngNameChange(e) {
    const nameRegex = /^[a-z|A-Z| |]+$/;
    if (e.target.value === "" || nameRegex.test(e.target.value)) {
      setEngNameValue(e.target.value);
    }
  }
  function handleAdress(_postcode, _fullAdress, _engAddress) {
    setPostcode(_postcode);
    setFullAdress(_fullAdress);
    setEngAddress(_engAddress);
    setModalAdressState(false);
  }
  function handleModalClose() {
    setModalAdressState(false);
    setModalPrivacyState(false);
    setModalIsolationState(false);
  }
  function handleFormEnd() {
    if (!idValidated) {
      toast.error("주민등록번호 또는 생년월일 형식이 잘못되었습니다.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (showModalPrivacy === false) {
      toast.error("개인정보 취급 처리 방침을 확인하세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (showModalIsolation === false) {
      toast.error("자가격리 준수사항을 확인하세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (agreeState === false) {
      toast.error(
        "개인정보 수집·이용 및 자가격리 준수사항 미동의시 본 병원 서비스를 이용하실 수 없습니다.",
        {
          autoClose: 4000,
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (!isChild && phoneValue === "") {
      toast.error("전화번호를 입력해주세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (isChild && parentPhone === "") {
      toast.error("보호자 전화번호를 입력해주세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      nameValue === "" ||
      idValue === "" ||
      gender === "" ||
      postcode === ""
    ) {
      toast.error("빈 항목을 확인해주세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setPageInfo("2");
    }
  }
  function handleGoToBack() {
    setPageInfo("1");
  }

  useEffect(() => {
    // 주민등록번호 하이폰 자동 생성
    if (idValue.length === 13) {
      setIdValue(idValue.replace(/(\d{6})(\d{7})/, "$1-$2"));
    }
    // 주민등록번호 유효성 검사
    if (idValue.length === 14) {
      const idRegex =
        /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[0-9]\d{6}/;
      if (!idRegex.test(idValue)) {
        setIdValue(""); // 주민등록번호 형식 오류시 비우기 처리
        toast.error("주민등록번호가 잘못되었습니다.", {
          autoClose: 4000,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    // 주민등록번호 자릿수 유효성 검사
    if (ifBirthDate) {
      if (idValue.length === 6) {
        serIdValidated(true);
      } else {
        serIdValidated(false);
      }
    } else {
      if (idValue.length === 14) {
        serIdValidated(true);
      } else {
        serIdValidated(false);
      }
    }

    // 휴대폰번호 3-3-4 형식 하이폰 자동 생성
    if (phoneValue.length === 10) {
      setPhoneValue(phoneValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    // 휴대폰번호 3-4-4 형식 하이폰 자동 생성
    if (phoneValue.length === 13) {
      setPhoneValue(
        phoneValue
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
    // 부모 휴대폰번호 3-3-4 형식 하이폰 자동 생성
    if (parentPhone.length === 10) {
      setParentPhone(parentPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    // 부모 휴대폰번호 3-4-4 형식 하이폰 자동 생성
    if (parentPhone.length === 13) {
      setParentPhone(
        parentPhone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [nameValue, idValue, phoneValue, parentPhone, ifBirthDate, idValidated]);

  return pageInfo === "1" ? (
    <div>
      <Helmet>
        <title>개인정보 작성 - 전자문진 시스템</title>
      </Helmet>
      <ToastContainer />
      <HeaderForm />
      <div className={styles.formPage}>
        <div className={styles.container}>
          <div className={styles.container_content}>
            <Form className={styles.form}>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  성명(한글)
                </Form.Label>
                <Form.Control
                  onChange={handleNameChange}
                  value={nameValue}
                  size="lg"
                  type="text"
                  placeholder="성명"
                />
                <Form.Check
                  className={styles.for}
                  type="checkbox"
                  label="해외 출국용"
                  checked={isGoForeign}
                  onChange={() => {
                    setIsGoForeign(!isGoForeign);
                  }}
                />
              </Form.Group>

              {isGoForeign === true ? (
                // 해외출국용
                <Form.Group className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      영문 이름 (여권에 기재된 이름)
                    </Form.Label>
                    <Form.Control
                      onChange={handleEngNameChange}
                      value={engNameValue}
                      size="lg"
                      type="text"
                      placeholder="English Name"
                    />
                    <Form.Label
                      style={{
                        color: "red",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                      }}
                    >
                      ※ 띄어쓰기 및 대소문자 준수(비정확시 확인서 발급 불가)
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      주민등록번호
                    </Form.Label>
                    <Form.Check
                      style={{ marginLeft: "10px" }}
                      inline
                      type="checkbox"
                      label="없음"
                      checked={ifBirthDate}
                      onChange={() => {
                        setIfBirthDate(!ifBirthDate);
                      }}
                    />
                    {ifBirthDate ? (
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          생년월일
                        </Form.Label>
                        <Form.Control
                          onChange={handleIdChange}
                          value={idValue}
                          size="lg"
                          type="tel"
                          placeholder="ex) 990101"
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Control
                          onChange={handleIdChange}
                          value={idValue}
                          size="lg"
                          type="tel"
                          placeholder="'-' 없이 기재"
                        />
                      </Form.Group>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      여권 번호 (Passport Number)
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setPassportNumValue(e.target.value);
                      }}
                      value={passportNumValue}
                      size="lg"
                      type="text"
                      placeholder="Passport Number"
                    />
                  </Form.Group>
                </Form.Group>
              ) : (
                // 일반 용
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    주민등록번호
                  </Form.Label>
                  <Form.Check
                    style={{ marginLeft: "10px" }}
                    inline
                    type="checkbox"
                    label="없음"
                    checked={ifBirthDate}
                    onChange={() => {
                      setIfBirthDate(!ifBirthDate);
                    }}
                  />
                  {ifBirthDate ? (
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        생년월일
                      </Form.Label>
                      <Form.Control
                        onChange={handleIdChange}
                        value={idValue}
                        size="lg"
                        type="tel"
                        placeholder="예시) 990101"
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Control
                        onChange={handleIdChange}
                        value={idValue}
                        size="lg"
                        type="tel"
                        placeholder="'-' 없이 기재"
                      />
                    </Form.Group>
                  )}
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>전화번호</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="미성년자"
                  checked={isChild}
                  onChange={() => {
                    setIsChild(!isChild);
                  }}
                />
                {isChild === true ? (
                  <div>
                    <Form.Label
                      style={{
                        fontSize: "0.8em",
                      }}
                    >
                      ※ 미성년자 본인 전화번호 없을 시{" "}
                      <strong>보호자 번호만 입력</strong>
                    </Form.Label>
                    <Form.Control
                      onChange={handlePhoneChange}
                      value={phoneValue}
                      size="lg"
                      type="tel"
                      placeholder="본인 전화번호"
                    />
                    <Form.Control
                      onChange={handleParentPhoneChange}
                      value={parentPhone}
                      size="lg"
                      type="tel"
                      placeholder="보호자 전화번호"
                    />
                    <Form.Label
                      style={{
                        color: "red",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                      }}
                    >
                      ※ 전화번호가 비정확시 결과 안내 및 확인서 발급 불가
                    </Form.Label>
                  </div>
                ) : (
                  <Form.Group>
                    <Form.Control
                      onChange={handlePhoneChange}
                      value={phoneValue}
                      size="lg"
                      type="tel"
                      placeholder="전화번호"
                    />
                    <Form.Label
                      style={{
                        color: "red",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                      }}
                    >
                      ※ 전화번호가 비정확시 결과 안내 및 확인서 발급 불가
                    </Form.Label>
                  </Form.Group>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  label="남성"
                  name="group1"
                  type="radio"
                  checked={gender === "남성" ? true : false}
                  onChange={() => {
                    setGender("남성");
                  }}
                />
                <Form.Check
                  inline
                  label="여성"
                  name="group1"
                  type="radio"
                  checked={gender === "여성" ? true : false}
                  onChange={() => {
                    setGender("여성");
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontWeight: "bold" }}>직업</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setJob(e.target.value);
                  }}
                  defaultValue="0"
                  size="lg"
                >
                  <option value="0" disabled>
                    직업 선택
                  </option>
                  <option value="의회의원,고위임직원 및 관리자">
                    의회의원,고위임직원 및 관리자
                  </option>
                  <option value="전문가">전문가</option>
                  <option value="기술공 및 준전문가">기술공 및 준전문가</option>
                  <option value="사무종사자">사무종사자</option>
                  <option value="서비스종사자">서비스종사자</option>
                  <option value="판매종사자">판매종사자</option>
                  <option value="농업 및 어업숙련 종사자">
                    농업 및 어업숙련 종사자
                  </option>
                  <option value="기능원 및 기능관련 종사자">
                    기능원 및 기능관련 종사자
                  </option>
                  <option value="장치,기계조작 및 조립종사자">
                    장치,기계조작 및 조립종사자
                  </option>
                  <option value="단순노무 종사자">단순노무 종사자</option>
                  <option value="군인">군인</option>
                  <option value="(전업)주부">(전업)주부</option>
                  <option value="학생">학생</option>
                  <option value="무직">무직</option>
                  <option value="기타">기타</option>
                </Form.Select>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="직장 또는 학교명(무직시 공란)"
                  value={detailJob}
                  onChange={(e) => {
                    setDetailJob(e.target.value);
                  }}
                />
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "bold" }}>주소</Form.Label>
                  <Row>
                    <Col xs={8}>
                      <Form.Control
                        onChange={handleNameChange}
                        value={postcode}
                        size="lg"
                        type="text"
                        placeholder="우편번호"
                        readOnly
                      />
                    </Col>
                    <Col xs={4}>
                      <Button
                        onClick={() => {
                          setModalAdressState(!modalAdressState);
                        }}
                        className={styles.searchAddressButton}
                        size="lg"
                      >
                        검색
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    onChange={handleNameChange}
                    value={fullAdress}
                    size="lg"
                    type="text"
                    placeholder="주소"
                    readOnly
                  />
                  <Form.Control
                    onChange={(e) => {
                      setDetailAdress(e.target.value);
                    }}
                    value={detailAdress}
                    readOnly={fullAdress === "" ? true : false}
                    size="lg"
                    type="text"
                    placeholder="상세주소 입력"
                  />
                </Form.Group>
              </Form.Group>
            </Form>
            <div>
              <span className={styles.privacy_title}>
                개인정보 취급 처리 방침
              </span>
              <Button
                onClick={() => {
                  setModalPrivacyState(!modalPrivacyState);
                  setShowModalPrivacy(true);
                }}
                className={styles.searchAddressButton}
                size="sm"
              >
                보기
              </Button>
            </div>
            <div>
              <span className={styles.privacy_title}>자가격리 준수사항</span>
              <Button
                onClick={() => {
                  setModalIsolationState(!modalIsolationState);
                  setShowModalIsolation(true);
                }}
                className={styles.searchAddressButton}
                size="sm"
              >
                보기
              </Button>
            </div>
            <br />
            <div className={styles.privacy_agree}>
              귀하는 본 병원의 개인정보 수집·이용 및 자가격리 준수사항을
              동의합니까?
            </div>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                label="예"
                name="group1"
                type="radio"
                onChange={() => {
                  setAgreeState(true);
                }}
              />
              <Form.Check
                inline
                label="아니요"
                name="group1"
                type="radio"
                onChange={() => {
                  setAgreeState(false);
                  toast.error(
                    "개인정보 수집·이용 및 자가격리 준수사항 미동의시 본 병원 서비스를 이용하실 수 없습니다.",
                    {
                      autoClose: 4000,
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                }}
              />
            </Form.Group>
            <span onClick={handleFormEnd} className={styles.button}>
              <span className={styles.button_text}>다음 페이지</span>
            </span>
            <UserPrivacyModal
              modalState={modalPrivacyState}
              handleModalClose={handleModalClose}
            />
            <Isolation
              modalState={modalIsolationState}
              handleModalClose={handleModalClose}
            />
            <DaumAdressAPI
              modalState={modalAdressState}
              handleAdress={handleAdress}
              handleModalClose={handleModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Form2 data={sendProps} handleGoToBack={handleGoToBack} />
  );
}

export default Form1;
