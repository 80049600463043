import React, { useEffect, useState } from "react";
import HeaderForm from "../../../header_form/header_form";
import { Form } from "react-bootstrap";
import styles from "./form2.module.css";
import moment from "moment";
import Symptom from "./symptom/symptom";
import { ToastContainer, toast } from "react-toastify";
import CompleteModal from "../../complete/completeModal";
import { useNavigate } from "react-router-dom";
import { ref, set, serverTimestamp } from "firebase/database";
import { database } from "../../../../firebaseConfig";
import uuid from "react-uuid";
import { Helmet } from "react-helmet";
import LoadingModal from "../../loadingModal/loadingModal";

function Form2(props) {
  const navigate = useNavigate();
  const formUUID = uuid();
  const form1Data = props.data;

  //검사방법
  const [ART, setART] = useState(false);
  const [PCR, setPCR] = useState(false);
  const method = [];

  const [needPrint, setNeedPrint] = useState(false);

  const [symptomComplete, setSymptomComplete] = useState(false);
  const isGoForeign = form1Data.isGoForeign;
  const [noSymptom, setNoSymptom] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [symptoms, setSymptoms] = useState([]);

  const [onSendLoading, setOnSendLoading] = useState(false);
  const [completeSendedDB, setCompleteSendedDB] = useState(false);

  function handleSymptom(_data) {
    setSymptoms(_data);
  }
  function handleComplete() {
    setSymptomComplete(true);
  }

  function handleToDB() {
    if (ART) {
      method.push("ART");
    }
    if (PCR) {
      method.push("PCR");
    }
    if (method.length === 0) {
      toast.error("검사방법을 선택해주세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (noSymptom === false && symptoms.length === 0) {
      toast.error("증상을 선택해주세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (needPrint === undefined) {
      toast.error("한글 음성확인서 발급 여부를 선택해주세요.", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setOnSendLoading(true);
      sendtoDB();
    }
  }
  function sendtoDB() {
    set(ref(database, "data/" + formUUID), {
      key: formUUID,
      nameValue: form1Data.nameValue,
      isGoForeign: form1Data.isGoForeign,
      idValue: form1Data.idValue,
      phoneValue: form1Data.phoneValue,
      isChild: form1Data.isChild,
      parentPhone: form1Data.parentPhone,
      engName: form1Data.engNameValue,
      passportNum: form1Data.passportNumValue,
      gender: form1Data.gender,
      job: form1Data.job,
      detailJob: form1Data.detailJob,
      postcode: form1Data.postcode,
      fullAddress: form1Data.fullAdress,
      detailAddress: form1Data.detailAdress,
      engAddress: form1Data.engAddress,
      symptomStart: startDate,
      symptoms: symptoms,
      writeTime: serverTimestamp(),
      visited: false,
      reported: false,
      smsCount: 0,
      needPrint: needPrint,
      method: Array.from(new Set(method)), //검사방법 DB전송시 유니크(중복되지 않게)한 값만 전송
    })
      .then(() => {
        setOnSendLoading(false);
        setCompleteSendedDB(true);
      })
      .catch((error) => {
        toast.error("통신 에러입니다. 잠시 후 다시 시도해 주세요.", {
          autoClose: 4000,
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  useEffect(() => {
    if (isGoForeign === true) {
      setNoSymptom(true);
    }
    if (form1Data === undefined) {
      alert("잘못된 접근입니다.");
      navigate("/");
    }
    if (noSymptom === true) {
      setSymptoms(["무증상"]);
    }
  }, [isGoForeign, form1Data, noSymptom, navigate]);
  return (
    <div className={styles.form}>
      <Helmet>
        <title>증상 선택 - 전자문진 시스템</title>
      </Helmet>
      <ToastContainer />
      <HeaderForm />
      <div className={styles.formPage}>
        <div className={styles.container}>
          <div className={styles.container_content}>
            <Form className={styles.form}>
              <Form.Group className="mb-3">
                <Form.Group>
                  <Form.Label style={{ color: "red", fontWeight: "bold" }}>
                    검사유형 선택 (중복 선택 가능)
                  </Form.Label>
                </Form.Group>
                <Form.Group style={{ marginTop: "-15px" }}>
                  <Form.Check
                    className={styles.no_symptom}
                    inline
                    type="checkbox"
                    label="신속항원검사(ART)"
                    value={ART}
                    checked={ART}
                    onChange={() => {
                      setART(!ART);
                    }}
                  />
                  <Form.Check
                    className={styles.no_symptom}
                    inline
                    type="checkbox"
                    label="정밀검사(PCR)"
                    value={PCR}
                    checked={PCR}
                    onChange={() => {
                      setPCR(!PCR);
                    }}
                  />
                </Form.Group>
              </Form.Group>
              <hr width="100%" color="black" style={{ marginLeft: "-5px" }} />
              <Form.Label style={{ color: "red", fontWeight: "bold" }}>
                음성확인 서류 발급 여부
              </Form.Label>
              <Form.Group style={{ marginTop: "-15px" }}>
                <Form.Check
                  className={styles.no_symptom}
                  inline
                  type="radio"
                  label="예"
                  checked={needPrint ? true : false}
                  name="needPrint"
                  onChange={() => {
                    setNeedPrint(true);
                  }}
                />
                <Form.Check
                  className={styles.no_symptom}
                  inline
                  type="radio"
                  label="아니요"
                  checked={!needPrint ? true : false}
                  name="needPrint"
                  onChange={() => {
                    setNeedPrint(false);
                  }}
                />
              </Form.Group>
              <Form.Label style={{ fontWeight: "bold", fontSize: "0.9em" }}>
                ※서류 발급과 관계없이{" "}
                <strong style={{ color: "red" }}>결과</strong>는{" "}
                <strong style={{ color: "red" }}>카톡</strong>으로 전송됩니다.
              </Form.Label>
              <hr width="100%" color="black" style={{ marginLeft: "-5px" }} />
              <Form.Group className="mb-3">
                <Form.Check
                  className={styles.no_symptom}
                  type="checkbox"
                  label="무증상"
                  value={noSymptom}
                  checked={noSymptom}
                  onChange={() => {
                    setNoSymptom(!noSymptom);
                  }}
                />
                {isGoForeign ? (
                  <Form.Label>
                    ※해외 출국용은 무 증상자만 검사 가능합니다.※
                  </Form.Label>
                ) : null}
                {noSymptom === false ? (
                  <Form.Group>
                    <Form.Label>증상 시작일</Form.Label>
                    <Form.Control
                      size="lg"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      style={{ marginBottom: "15px" }}
                    />

                    <Form.Label>증상 선택(중복 선택 가능)</Form.Label>
                    {!symptomComplete ? (
                      <Symptom
                        handleSymptom={handleSymptom}
                        handleComplete={handleComplete}
                      />
                    ) : null}

                    {symptomComplete ? (
                      <span
                        className={styles.button}
                        onClick={() => {
                          setSymptomComplete(false);
                        }}
                      >
                        <span className={styles.button_text}>
                          증상 다시선택
                        </span>
                      </span>
                    ) : null}
                  </Form.Group>
                ) : null}
              </Form.Group>
            </Form>
            <span className={styles.buttons}>
              <span
                className={styles.button_back}
                onClick={props.handleGoToBack}
              >
                <span className={styles.button_text_back}>이전</span>
              </span>
              <span className={styles.button_end} onClick={handleToDB}>
                <span className={styles.button_text_end}>제출</span>
              </span>
            </span>
            <LoadingModal modalState={onSendLoading} />
            <CompleteModal modalState={completeSendedDB} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form2;
