import React from "react";
import styles from "./footer.module.css";

function Footer(props) {
  return (
    <div className={styles.footer}>
      <hr width="95%" color="black" />
      <span>© 2022 김숙자소아·청소년병원. All RIGHTS RESERVED.</span>
      <span>
        개발자 : <a href="mailto:dev@hongrok.net">김홍록</a>
      </span>
    </div>
  );
}

export default Footer;
