import React from "react";
import Modal from "react-modal/lib/components/Modal";
import styles from "./loadingModal.module.css";
import { PulseLoader } from "react-spinners";

function LoadingModal(props) {
  return (
    <Modal
      className={styles.modal}
      ariaHideApp={false}
      isOpen={props.modalState}
    >
      <div className={styles.contanier_content}>
        <PulseLoader color="#77c66e" size={"15px"} margin={7} />
        <span className={styles.complete_text}>데이터 전송중...</span>
      </div>
    </Modal>
  );
}

export default LoadingModal;
