import DaumPostCode from "react-daum-postcode";
import React from "react";
import Modal from "react-modal/lib/components/Modal";
import { IoClose } from "react-icons/io5";

function DaumAdressAPI(props) {
  const postCodeStyle = {
    position: "absolute",
    width: "calc( 100% - 40px )",
    height: "calc( 100% - 50px )",
  };
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let engAddress = data.addressEnglish;

    let postcode = data.zonecode;
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    props.handleAdress(postcode, fullAddress, engAddress);
  };

  return (
    <Modal
      style={{
        content: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
          border: "1px solid #ccc",
          background: "white",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "10px",
          outline: "none",
          padding: "20px",
        },
      }}
      ariaHideApp={false}
      isOpen={props.modalState}
      onRequestClose={() => {
        props.handleModalClose();
      }}
    >
      <div>
        <IoClose size="30px" onClick={props.handleModalClose} />
        <DaumPostCode onComplete={handleComplete} style={postCodeStyle} />
      </div>
    </Modal>
  );
}

export default DaumAdressAPI;
