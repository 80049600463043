import React from "react";

function IsolationNotice(props) {
  return (
    <div>
      <div className="page" title="Page 1">
        <div className="section">
          <div className="layoutArea">
            <div className="column">
              <p>
                <strong>[안내사항]</strong>
              </p>
              <ol>
                <li>
                  <p>
                    귀하는 「감염병의 예방 및 관리에 관한 법률」 제 49조 제1항
                    제14호에 따라 감염병원체에 감염되었 다고 의심되는 사람 또는
                    동범 제 41조 제3항 제2호에 따라 감염병환자등과 접촉하여
                    감염병이 감염 되거나 전파될 우려가 있는 사람에 해당하여
                    &lsquo;격리대상&rsquo;임을 통지합니다.
                  </p>
                </li>
                <li>
                  <p>
                    위 사항 위반 시 「감염병의 예방 및 관리에 관한 법률」
                    <br /> 제 80조 제 4호 또는 제7호에 따라 1년 이하의 징역 또는
                    1,000만원 이하의 벌금형에 처할 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>생활지원비 지원대상은 아님</p>
                  <p>
                    <strong>
                      상기 본인은 위 통지내용에 대한 설명내용을 들었음을
                      확인하며 자가격리 준수사항을 지킬 것을 동의 함.
                    </strong>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IsolationNotice;
