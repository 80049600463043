import React from "react";
import Modal from "react-modal/lib/components/Modal";
import styles from "./completeModal.module.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function CompleteModal(props) {
  const navigate = useNavigate();
  return (
    <Modal
      className={styles.modal}
      ariaHideApp={false}
      isOpen={props.modalState}
    >
      <div className={styles.contanier_content}>
        <BsCheckCircleFill color="#77c66e" size="150px" />
        <span className={styles.complete_text}>
          전자문진 작성이 완료되었습니다.
        </span>
        <span
          className={styles.button}
          onClick={() => {
            navigate("/");
          }}
        >
          <span className={styles.button_text}>처음 화면으로</span>
        </span>
      </div>
    </Modal>
  );
}

export default CompleteModal;
