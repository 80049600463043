import React from "react";
import Header from "../../header/header";
import styles from "./homePage.module.css";
import { RiErrorWarningFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Footer from "../../footer/footer";
import { Helmet } from "react-helmet";

function HomePage(props) {
  return (
    <div className={styles.homePage}>
      <Helmet>
        <title>김숙자소아청소년병원 | 코로나 19 전자문진 시스템</title>
      </Helmet>
      <Header />
      <div className={styles.body}>
        <div className={styles.contanier}>
          <div className={styles.container_content}>
            <img className={styles.img} src="/hp_logo_new.png" alt="logo" />
            <div className={styles.info}>
              <strong className={styles.title}>PCR 검사 소요시간 안내</strong>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>채취 시간</th>
                    <th>결과</th>
                  </tr>
                  <tr>
                    <td>(월~일)</td>
                    <td>~09:20 까지</td>
                    <td>13:00~13:30</td>
                  </tr>

                  <tr>
                    <td>(월~일)</td>
                    <td>~14:20 까지</td>
                    <td>17:00~18:00</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>(월~금)</td>
                    <td>~17:00 까지</td>
                    <td>19:00 이후</td>
                  </tr>
                </thead>
              </table>
            </div>
            <div className={styles.box}>
              <strong className={styles.button_top_info}>
                전자 문진표는 예약시스템이 아닙니다
              </strong>
              <Link to="/form" className={styles.button}>
                <span className={styles.button_text}>전자 문진표 작성하기</span>
              </Link>
            </div>
            <div className={styles.warn_info}>
              <RiErrorWarningFill color="#d7544f" />
              <span>작성한 당일에만 정보가 유효합니다.</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
