import React from "react";
import styles from "./header_form.module.css";
import logo from "./hp_new_favicon.png";

function HeaderForm(props) {
  return (
    <div className={styles.header}>
      <img className={styles.logo} src={logo} alt="logo" />
      <strong className={styles.title}>전자 문진표 작성하기</strong>
    </div>
  );
}

export default HeaderForm;
