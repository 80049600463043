import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomePage from "./components/pages/homePage/homePage.jsx";
import "./App.css";
import Form1 from "./components/pages/forms/form1/form1.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path="/form" element={<Form1 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
