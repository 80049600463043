import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBFL8Mi4XOahu88i9i7s5l_VLYbLxYMAH8",
  authDomain: "covid-form-fd1dc.firebaseapp.com",
  databaseURL: "https://covid-form-fd1dc-default-rtdb.firebaseio.com",
  projectId: "covid-form-fd1dc",
  storageBucket: "covid-form-fd1dc.appspot.com",
  messagingSenderId: "103214368194",
  appId: "1:103214368194:web:b2b62ec7260fba0d5e425b",
  measurementId: "G-FGMRZXLV9Y",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
